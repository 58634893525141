.new-ecr-ticker {
    font-family: var(--ecr-client-font-family, Arial);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: block;
    padding-bottom: 16px;
    max-height: 496px;
    overflow: hidden;

    .new-teasers {
        all: unset;
        overflow: hidden;
        list-style: none;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
    }
}

.divider-top {
    border-top: 1px solid #e3e4e7;
    width: 100%;
    //height: 16px;

    @media all and (min-width: 415px) and (max-width: 550px) {
        //height: 14px;
    }

    @media all and (max-width: 414px) {
        //height: 14px;
    }
}

.divider-bottom {
    border-top: 1px solid #e3e4e7;
    width: 100%;
    height: 24px;
    margin-top: 16px;

    @media (max-width: 550px) {
        margin-top: 12px;
    }
}

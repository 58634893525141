.ecr-teaser-list {
    img {
        max-width: unset; // override because is set to 100% in WEST
    }

    // only show if css is loaded
    display: block;

    .header-box {
        display: grid;
        grid-template-columns: 0 auto;

        .logo {
            position: relative;
            height: 30px;
            width: 22px;
        }

        .header-line {
            position: absolute;
            margin-left: 32px;
            width: 80px;
            border-top: solid 1px var(--ecr-client-primary-color, black);
        }

        & > h2 {
            margin: 0;
            padding: 4px 0 0 32px;
            font-family: var(--ecr-client-font-family, Arial);
            font-weight: 700;
            font-size: 22px;
            line-height: 31px;
            color: #4a4a4a;
        }
    }

    .teasers {
        padding: 16px 0 20px 0;

        & > *:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    .footer-box {
        width: 100%;
        display: flex;
        justify-content: end;

        .footer-line {
            width: 80px;
            border-bottom: solid 1px #707070;
        }
    }

    @media all and (min-width: 415px) and (max-width: 550px) {
        .header-box {
            .logo {
                height: 33px;
                width: 24px;
            }

            .header-line {
                margin-left: 39px;
                width: 120px;
            }

            h2 {
                padding: 8px 0 0 39px;
                font-size: 24px;
                line-height: 34px;
            }
        }

        .teasers {
            padding: 30px 0;

            & > *:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }

        .footer-box {
            .footer-line {
                width: 120px;
            }
        }
    }

    @media all and (min-width: 551px) {
        .header-box {
            .logo {
                height: 40px;
                width: 28px;
            }

            .header-line {
                margin-left: 45px;
                width: 160px;
            }

            h2 {
                padding: 8px 0 0 45px;
                font-size: 26px;
                line-height: 36px;
            }
        }

        .teasers {
            padding: 40px 0;

            & > *:not(:last-of-type) {
                margin-bottom: 30px;
            }
        }

        .footer-box {
            .footer-line {
                width: 160px;
            }
        }
    }
}

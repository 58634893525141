.text-truncate-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    align-self: stretch;

    @media all and (min-width: 415px) and (max-width: 550px) {
        -webkit-line-clamp: 3;
    }

    @media all and (max-width: 414px) {
        -webkit-line-clamp: 3;
    }

    @media all and (max-width: 290px) {
        -webkit-line-clamp: 3;
    }

    .paid-content-svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        position: relative;
        top: 3px;
    }
}

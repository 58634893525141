/*!
 * This document contains trade secret data which is the property of
 * Ippen Digital GmbH & Co. KG. Information contained herein may not be
 * used, copied or disclosed in whole or part except as permitted by
 * written agreement from Ippen Digital GmbH & Co. KG.
 *
 * Copyright (C) 2007- "2022" Ippen Digital GmbH & Co. KG / Munich / Germany
 */

@import '../../../css/shared.scss';

.ecr-teaser-list-swipable {
    margin-left: 9px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;

    img {
        max-width: unset; // override because is set to 100% in WEST
    }

    ::-webkit-scrollbar {
        height: 0;
    }

    a {
        font-size: 14px;
        line-height: 16px;
        color: #4a4a4a;
        text-decoration: none;
        text-transform: none;
    }

    & > h2 {
        margin: 0;
        padding: 28px 0 6px 0;
        font-weight: 700;
        font-size: 17px;
        line-height: 26px;
        text-transform: uppercase;
        color: #4a4a4a;
    }

    .container {
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        margin-bottom: 59px;
    }

    .arrow {
        position: absolute;
        z-index: 100;
        top: 40%;
        cursor: pointer;
    }

    .leftArrow {
        left: 3px;
    }

    .rightArrow {
        right: -2px;
    }

    .teasers {
        display: flex;
        padding-bottom: 10px;
        z-index: 10;
        margin-left: 52px;
        margin-right: 52px;

        > *:not(:last-child) {
            margin-right: 10px;
        }
    }

    .additional-teasers {
        display: flex;
        z-index: 10;

        > *:not(:last-child) {
            margin-right: 10px;
        }
    }

    .radar {
        position: absolute;
        left: -13px;
        bottom: 13px;
        z-index: 100;
        width: 56px;
        height: 56px;
    }

    .myArea {
        position: absolute;
        bottom: 2px;
        left: 52px;
        z-index: 100;
        height: 48px;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: Arial, sans-serif;
    }

    .logo {
        height: 42px;
        width: 34px;
        position: absolute;
        top: 56px;
        left: -4px;
        z-index: 100;
    }

    .tracked-container {
        border-radius: 12px;
    }

    @media all and (min-width: 415px) and (max-width: 550px) {
        .header-box {
            .logo {
                height: 45px;
                width: 36px;
            }

            h2 {
                padding: 32px 0 20px 0;
                font-size: 19px;
                line-height: 28px;
            }
        }

        .teasers,
        .added-teasers {
            a {
                font-size: 16px;
            }
        }
    }

    @media all and (min-width: 551px) {
        .header-box {
            .logo {
                height: 48px;
                width: 38px;
            }

            h2 {
                padding: 32px 0 20px 0;
                font-size: 21px;
                line-height: 28px;
            }
        }

        .teasers,
        .added-teasers {
            margin-left: 0;
            margin-right: 0;

            a {
                font-size: 18px;
            }
        }
    }
}

/*!
 * This document contains trade secret data which is the property of
 * Ippen Digital GmbH & Co. KG. Information contained herein may not be
 * used, copied or disclosed in whole or part except as permitted by
 * written agreement from Ippen Digital GmbH & Co. KG.
 *
 * Copyright (C) 2007- "2022" Ippen Digital GmbH & Co. KG / Munich / Germany
 */
.ecr-ad-newsletter-slide {
    height: 367px;
    width: 211px;
    overflow: hidden;
    border-radius: 12px;
    font-family: var(--ecr-client-font-family, Arial);

    a {
        display: block;
        height: 100%;
        width: 100%;
        font-weight: 400;
    }

    .background-image {
        width: 100%;
        height: 100%;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#ec5f00, #b40079);
    }

    .logo-nl {
        margin-top: 22px;
    }

    .image-nl {
        height: 90px;
    }

    .text-nl {
        color: #ffffff;
        font-size: 22px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0 18px;
        margin: 0;
    }

    .newsletterButton {
        background: #ffffff;
        border-radius: 10px;
        padding: 10px 14px;
        color: #b40079;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        border: none !important;
    }
}

@media all and (min-width: 415px) and (max-width: 550px) {
    .ecr-ad-newsletter-slide {
        width: 230px;
        height: 394px;

        .image-nl {
            height: auto;
        }
    }
}

@media all and (min-width: 551px) {
    .ecr-ad-newsletter-slide {
        width: 230px;
        height: 400px;

        .image-nl {
            height: auto;
        }
    }
}

.new-headline {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 18px;
    padding-top: 16px;
    h2 {
        margin: 0;
        color: #000 !important;
        font-size: 24px;
        line-height: 20px;
        font-weight: 700;
        padding-top: 12px;
    }

    .update-button {
        width: 24px;
        height: 24px;
        display: flex;
    }

    .more-article {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        &:hover {
            cursor: pointer !important;
        }
    }
}

.refresh-button {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    transition: color 0.3s ease;
    padding: 0;
    color: #707070;

    &:hover {
        color: #000000;
    }
}

.x-users-reading-wrapper {
    &.swiper {
        position: absolute;
        top: 13px;
        left: 11px;
        height: 39px;
        width: 39px;
    }

    &.ticker {
        margin: 8px 10px 0 0;
        position: relative;
        bottom: 20px;

        .users-number {
            font-size: 10px;
            padding: 0;
            letter-spacing: -0.2px;
            width: 100%;
            line-height: 16px;
            left: -1px;
        }
    }

    .users-number {
        font: 8px Roboto;
        font-weight: 700;
        color: #4a4a4a;
        position: absolute;
        left: 0;
        text-align: center;
        width: 100%;
        bottom: 7px;
        padding: 1px 0 1px 0;
    }
}

@media all and (min-width: 551px) {
    .x-users-reading-wrapper {
        &.ticker {
            margin: 8px 16px 0 0;
        }
    }
}

.editorial-modification-date {
    background-color: var(--ecr-client-primary-color, black);
    font-family: var(--ecr-client-title-font-family);
    color: #ffffff;
    padding: 0 5px !important;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border-radius: 4px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    min-width: fit-content;
    width: fit-content;
}

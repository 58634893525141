/*!
 * This document contains trade secret data which is the property of
 * Ippen Digital GmbH & Co. KG. Information contained herein may not be
 * used, copied or disclosed in whole or part except as permitted by
 * written agreement from Ippen Digital GmbH & Co. KG.
 *
 * Copyright (C) 2007- "2022" Ippen Digital GmbH & Co. KG / Munich / Germany
 */

.ecr-teaser-slide {
    overflow: hidden;
    width: 211px;
    height: 367px;
    border-radius: 12px;
    position: relative;

    .bgImage {
        width: 100%;
        height: 100%;
    }

    .bgImage2 {
        transform: scaleY(-1);
        height: 98%; //um ein Bug mit einer weißen Linie in Safari zu beheben
    }

    @keyframes move {
        from {
            opacity: 1;
        }
        to {
            bottom: 56px;
            opacity: 0.9;
        }
    }

    //animations on older webkits using opacity are buggy and leads to opacity 0
    @-webkit-keyframes move {
        from {
        }
        to {
            bottom: 56px;
        }
    }

    a {
        color: #4a4a4a;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .whitebox {
        background-color: #ffffff;
        border-radius: 1px 1px 12px 12px;
        height: 164px;
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
        z-index: 5;

        .upsliding-box {
            padding: 10px;
            position: absolute;
            bottom: 15px;
            left: 15px;
            right: 15px;
            z-index: 10;
            background-color: #ffffff;
            animation: move 0.8s forwards;
            animation-delay: 2s;
            opacity: 1;
            height: 129px;

            .teaser-headline {
                font-size: 16px;
                font-weight: 700;
                line-height: 23px;
                text-overflow: ellipsis;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                word-wrap: break-word;
                white-space: break-spaces;
                box-sizing: border-box !important;
            }

            .new-story-icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 27px;
                height: 27px;
                z-index: 15;
            }

            .new-story-icon-spacer {
                display: inline-block;
                width: 27px;
            }

            .advertisement-icon {
                display: inline-block;
                margin-top: 3px;
                margin-right: 10px;
                vertical-align: text-top;
                width: 47px;
                height: 14px;
            }

            .paid-content-icon {
                display: inline-block;
                margin-right: 10px;
                vertical-align: sub;
                width: 17px;
                height: 17px;
            }

            .teaser-additional-info-wrapper {
                display: flex;
                justify-content: space-between;
                font-family: 'Roboto', sans-serif;
                font-size: 9px;
                line-height: 10px;
                font-weight: 400;
                color: #4a4a4a;
                height: 12px;
            }
        }

        .line {
            position: absolute;
            bottom: 46px;
            margin: auto;
            left: 0;
            right: 0;
        }

        .articleButton {
            width: 100%;
            border: none;
            position: absolute;
            bottom: 20px;
            font-family: Arial, sans-serif;
            font-size: 16px;
            color: #4a4a4a;
            background-color: #ffffff;
            cursor: pointer;
        }
    }

    @media all and (min-width: 415px) and (max-width: 550px) {
        .whitebox {
            height: 164px;

            .upsliding-box {
                .teaser-headline {
                    font-size: 18px;
                    line-height: 27px;
                    -webkit-line-clamp: 4;
                }

                &.with-date {
                    height: 143px;
                }

                .new-story-icon {
                    height: 33.5px;
                    width: 33.5px;
                }

                .new-story-icon-spacer {
                    width: 33.5px;
                }

                .advertisement-icon {
                    margin-right: 12px;
                }

                // to fix sometimes partially visible on tablet 6 line of text
                &:after {
                    content: '';
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                }
            }

            .line {
                bottom: 46px;
            }

            .articleButton {
                bottom: 16px;
            }
        }
    }

    @media all and (min-width: 551px) {
        .whitebox {
            height: 164px;

            .upsliding-box {
                .teaser-headline {
                    -webkit-line-clamp: 4;
                    font-size: 19px;
                    line-height: 28px;
                    bottom: 9px;
                }

                &.with-date {
                    height: 146px;
                }

                .new-story-icon {
                    height: 33.5px;
                    width: 33.5px;
                }

                .new-story-icon-spacer {
                    width: 33.5px;
                }

                .advertisement-icon {
                    margin-right: 12px;
                }

                .teaser-additional-info-wrapper {
                    font-size: 10px;
                    line-height: 11px;
                }
            }

            .line {
                bottom: 47px;
            }

            .articleButton {
                font-size: 17px;
                bottom: 16px;
            }
        }
    }

    &.ecr-contains-entity-type-icon {
        position: relative;
    }
}

@media all and (min-width: 415px) and (max-width: 550px) {
    .ecr-teaser-slide {
        width: 230px;
        height: 394px;
    }
}

@media all and (min-width: 551px) {
    .ecr-teaser-slide {
        width: 230px;
        height: 400px;
    }
}

@media all and (min-width: 415px) {
    .ecr-teaser-slide {
        .whitebox {
            .upsliding-box {
                box-sizing: unset !important;
                -webkit-box-sizing: unset !important;
            }
        }
    }
}

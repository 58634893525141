@font-face {
    font-display: optional;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src:
        url('https://idcdn.de/static-files/common/fonts/Roboto/Roboto400normal.woff2') format('woff2'),
        url('https://idcdn.de/static-files/common/fonts/Roboto/Roboto400normal.woff') format('woff');
}

@font-face {
    font-display: optional;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src:
        url('https://idcdn.de/static-files/common/fonts/Roboto/Roboto700normal.woff2') format('woff2'),
        url('https://idcdn.de/static-files/common/fonts/Roboto/Roboto700normal.woff') format('woff');
}

.ecr-ticker-teaser {
    a {
        text-decoration: none;
    }
}

.new-ticker-image {
    width: 114px;
    min-width: 114px;
    height: 85px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    object-fit: cover;
    object-position: center;

    @media all and (min-width: 415px) and (max-width: 550px) {
        width: 75px;
        min-width: 75px;
        height: 56px;
    }

    @media all and (max-width: 414px) {
        width: 75px;
        min-width: 75px;
        height: 56px;
    }
}

.new-text-container {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--ecr-ticker-text-font-size-lg);
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 85px;
    gap: 20px;
    h3 {
        color: var(--ecr-client-primary-color);
        font-family: var(--ecr-client-title-font-family);
        font-size: 13px;
        margin: 0;
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .title-wrapper {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding-bottom: var(--ecr-ticker-bottom-padding, 5px);
    }

    @media all and (min-width: 415px) and (max-width: 550px) {
        font-size:  var(--ecr-ticker-text-font-size-md);
        min-height: 88px;
    }

    @media all and (max-width: 414px) {
        font-size: var(--ecr-ticker-text-font-size-sm);
        min-height: 88px;
    }
}

.advertisement-story-text {
    color: #707070;
    font-family: var(--ecr-client-title-font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 10px;
}

.new-headline-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.new-headline-text {
    color: #000000;
}

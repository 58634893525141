.survey-slide {
    font-family: 'Roboto', sans-serif !important;
    width: 230px;
    height: 400px;
    max-height: 400px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
}

.survey-slide-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 551px) {
        padding: 3px 8px;
        margin: 5px;

        h3 {
            font-size: 16px;
        }
    }
}

.survey-slide-image {
    width: 202px;
    height: 151px;
}

.survey-slide-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.survey-slide-title {
    width: 180px;
    text-align: center;
    margin: 20px auto 10px auto;
    font-weight: bold !important;
}

.survey-slide-description {
    width: fit-content;
    text-align: center;
    margin: 0 auto 30px auto;
}

.survey-slide-button {
    text-align: center;
    width: 88%;
    margin: auto;
    max-width: 210px;
    border-radius: 30px;
    background-color: #919da8;
    border: 1px solid #919da8;
    color: #fff;
    text-decoration: none;
    color: #fff !important;
    font-size: 14px !important;
    line-height: 15px !important;
    padding: 12px 10px;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
    }

    @media all and (max-width: 551px) {
        height: 31px;
    }
}

.ecr-teaser {
    font-family: var(--ecr-client-font-family, Arial);
    overflow: hidden;
    flex: 1 1 auto;

    a {
        display: flex;
        text-decoration: none;
        color: var(--ecr-client-headline-color, #000);

        .image-container {
            display: flex;
            min-width: 38%;
            width: 38%;
            align-self: flex-start;

            &:before {
                content: '';
                // padding depends on width, so setting a pseudo padding with aspect ratio of 4:3
                padding-top: calc(100% / (4 / 3));
            }

            img {
                width: 100%;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            word-break: break-word;
            padding-left: 10px;
            width: 100%;

            .teaser-additional-info-wrapper {
                display: flex;
                justify-content: space-between;
                font-family: 'Roboto', sans-serif;
                font-size: 10px;
                line-height: 11px;
                font-weight: 400;
                color: #4a4a4a;
                height: 11px;
            }

            .kicker {
                color: var(--ecr-client-dachzeile-color, #000);
                text-decoration: var(--ecr-client-dachzeile-text-decoration, underline);
                text-transform: var(--ecr-client-dachzeile-text-transform, none);
                letter-spacing: var(--ecr-client-dachzeile-letter-spacing, normal);
                font-weight: var(--ecr-client-dachzeile-font-weight, 700);
                line-height: var(--ecr-client-dachzeile-line-height-mobile, 24px);
                margin-bottom: 6px;
                font-size: 12px;
                text-underline-offset: 3px;
                text-decoration-color: #ffde12;
                text-decoration-thickness: 3px;
                text-decoration-skip-ink: none;
            }

            .headline {
                display: -webkit-box;
                font-size: 16px;
                font-weight: 700;
                line-height: 23px;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                position: relative;

                &.headline-under-kicker {
                    -webkit-line-clamp: 3;
                }

                .square-headline-icon {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: sub;
                    width: 17px;
                    height: 17px;
                }

                .advertisement-icon {
                    display: inline-block;
                    margin-top: 3px;
                    margin-right: 10px;
                    vertical-align: text-top;
                    width: 47px;
                    height: 14px;
                }
            }
        }
    }

    @media all and (min-width: 415px) and (max-width: 550px) {
        a {
            .image-container {
                min-width: 35%;
                width: 35%;
            }

            .text-container {
                padding-left: 20px;

                .kicker {
                    line-height: var(--ecr-client-dachzeile-line-height-tablet, 24px);
                }

                .headline {
                    font-size: 18px;
                    line-height: 27px;

                    .square-headline-icon {
                        height: 21px;
                        width: 21px;
                        margin-right: 12px;
                    }

                    .advertisement-icon {
                        margin-right: 12px;
                    }
                }
            }
        }
    }

    @media all and (min-width: 551px) {
        a {
            .image-container {
                min-width: 25%;
                width: 25%;
            }

            .text-container {
                .kicker {
                    margin-bottom: 8px;
                    font-size: 14px;
                    line-height: 24px;
                }

                .headline {
                    -webkit-line-clamp: 3;
                    font-size: 22px;
                    line-height: 32px;

                    .square-headline-icon {
                        height: 25px;
                        width: 25px;
                        margin-right: 14.4px;
                    }

                    .advertisement-icon {
                        margin-top: 4px;
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}
